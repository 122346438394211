import { ReactNode } from 'react'
import styled from 'styled-components'
import { MQ, colors, headerHeight } from '../../styles'
import { DefaultHeader } from '../organisms/DefaultHeader'
import { TopShelfView } from '../organisms/TopShelfView'

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position:relative;
`

const Contents = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 40px;

  ${MQ.ONE_COLUMN} {
    display: block;
  }
`

const LeftWrap = styled.div`
  flex: 1;
  position: relative;
  padding-left: 40px;
  ${MQ.ONE_COLUMN} {
    display: none;
  }
`

const ShelfWrap = styled.div`
  margin-top: 20px;
`

const PageContentsWrap = styled.div`
  width: 100%;
  max-width: 400px;
  position: sticky;
  top: 20px;
  right: 0px;
  padding-right: 40px;
  height: calc(100vh - 60px);
  z-index: 11;

  ${MQ.ONE_COLUMN} {
    top:0px;
    right:0px;
    position: relative;
    max-width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    height: auto;
  }
`

const PageContents = styled.div`
  ${MQ.ONE_COLUMN} {
    max-width: 600px;
    width: 100%;
  }
`

const HeaderWrap = styled.header`
  top: 0px;
  left: 0px;
  height: ${headerHeight}px;
  position: sticky;
  background: ${colors.systemBackground};
  z-index: 10;
`

export const TopLayout: React.FC<{
  children: ReactNode
}> = ({ children }) => {

  return <Wrap>
    <Contents>
      <LeftWrap>
        <HeaderWrap>
          <DefaultHeader />
        </HeaderWrap>
        <ShelfWrap>
          <TopShelfView />
        </ShelfWrap>
      </LeftWrap>
      <PageContentsWrap>
        <PageContents>
          {children}
        </PageContents>
      </PageContentsWrap>
    </Contents>
  </Wrap>
}
