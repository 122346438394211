
import styled from 'styled-components';
import { ReactComponent as NotfoundIcon } from "../atoms/svgs/notfound.svg";
import { colors } from '../../styles';
import { NavLink } from 'react-router-dom';

const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  svg {
    width: 50px;
    fill: ${colors.label};
  }
`

const ToTop = styled.p`
  margin-top: 20px;
`

export const NotfoundPage: React.FC = () => {
  return <Wrap>
    <NotfoundIcon />
    <h1>Not Found</h1>
    <ToTop>
      <NavLink to="/">トップへ戻る</NavLink>
    </ToTop>
  </Wrap>
}
