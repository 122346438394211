import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from './styles'

const Wrap = styled.div`
  
`

const Canvas = styled.canvas`
  width: 600px;
  height: 315px;
  border: 1px solid ${colors.systemGray};
  display: none;
`

export const Root: React.FC = () => {
  return <Wrap>
    <Outlet />
    <Canvas id="ogp-canvas-element" width="1200" height="630" />
  </Wrap>
}