import { ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { MQ, colors } from '../../styles'

const Wrap = styled.div`
  top:0px;
  left:0px;
  width: 100dvw;
  height: 100dvh;
  position:fixed;
  z-index: 100;
  overscroll-behavior-y: none;
  overflow-y: auto;
`

const Bg = styled.div<{ $showed: boolean }>`
  background: rgba(0,0,0,0.0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 1px);
  transition: background 0.2s;


  ${({ $showed }) => $showed && `
    background: rgba(0,0,0,0.7);

    ${MQ.ONE_MODAL} {
      background: rgba(0,0,0,0.9);
    }
  `}
`

const CloseButton = styled.button<{ $showed: boolean }>`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  opacity: 0.0;
  &:hover {
    color: ${colors.accent};
  }

  transition: opacity 0.2s;

  ${({ $showed }) => $showed && `
    opacity: 1.0;
  `}
`

const ContentsWrap = styled.div<{ $showed: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100% - 20px);
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  transform: translateY(20px);

  ${MQ.ONE_MODAL} {
    padding-top: 60px;
  }

  ${({ $showed }) => $showed && `
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  `}
`

const useShowed = (onClose: () => void) => {
  const [showed, setShowed] = useState(false)
  const show = useCallback(() => {
    const timerId = setTimeout(() => {
      clearTimeout(timerId)
      setShowed(true)
    }, 100)
  }, [])
  const hide = useCallback(() => {
    setShowed(false)
    const timerId = setTimeout(() => {
      clearTimeout(timerId)
      onClose()
    }, 300)
  }, [onClose])
  return { showed, show, hide }
}

export const ModalLayout: React.FC<{
  children: ReactNode
  onClose: () => void
}> = ({ children, onClose }) => {
  const { showed, show, hide } = useShowed(onClose)
  useEffect(() => {
    show()
  }, [show])
  return <Wrap>
    <Bg $showed={showed} />
    <ContentsWrap $showed={showed} onClick={hide}>
      {children}
    </ContentsWrap>
    <CloseButton $showed={showed} onClick={hide}>X</CloseButton>
  </Wrap>
}