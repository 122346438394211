import styled from 'styled-components'
import { LoginInputView } from '../molecules/LoginInputView'

const LoginViewWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100dvh - 40px);
  padding: 20px;
`

const LoginViewContents = styled.div`
  width: 100%;
`

export const LoginContents: React.FC = () => {
  return <LoginViewWrap>
    <LoginViewContents>
      <LoginInputView />
    </LoginViewContents>
  </LoginViewWrap>
}
