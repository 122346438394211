import styled from 'styled-components'
import { Book, amazonLink } from '../../../models/Book'
import { ModalLayout } from '../../templates/ModalLayout'
import { MQ, colors, ellipsis } from '../../../styles'
import { useCallback } from 'react'
import { PublicUser } from '../../../api/functionsApi'

const Contents = styled.div`
  padding: 40px;
  background: ${colors.systemBackground};
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: scroll;
  h1 {
    font-size: 1.5rem;
    ${ellipsis(1)}
  }

  ${MQ.ONE_MODAL} {
    max-height: 100%;
    border-radius: 10px 10px 10px 10px;
    padding: 20px;
    margin: 0px 30px;
    h1 {
      font-size: 1.2rem;
    }
  }
`

const BookInfoWrap = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  align-items: flex-start;
`

const BookCoverWrap = styled.div`
  width: 80px;
  aspect-ratio: 1/1.5;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${MQ.ONE_MODAL} {
    width: 60px;
  }
`

const BookInfo = styled.div`
  flex:1;
  display: grid;
  gap: 5px;
  h2 {
    font-size: 1.1rem;
  }
  h3{
    font-size: 1.0rem;
  }

  ${MQ.ONE_MODAL} {
    h2 {
      font-size: 1.0rem;
    }
    h3{
      font-size: 0.9rem;
    }
  }
`

const AmazonLink = styled.p`
  margin-top: 20px;
  text-align: right;
  a {
    color: ${colors.accent};
  }
`

const Description = styled.p`
  font-size: 0.8rem;
  margin-top: 20px;
`

const MemoWrap = styled.div`
  border-top: 1px solid ${colors.secondarySystemBackground};
  margin-top: 20px;
  padding-top: 20px;

  h4 {
    font-size: 0.8rem;
    color: ${colors.secondaryLabel};
  }
`

const MemoText = styled.p`
  margin-top:10px;
  ${MQ.SP} {
    font-size: 0.9rem;
  }
`


const NoCover = styled.div`
  padding: 20px 10px;
  text-align: center;
  background: ${colors.secondarySystemBackground};
  h4{
    font-size:12px;
    ${ellipsis(2)}
  }
  p{
    margin-top:20px;
    font-size: 10px;
    line-height: 1.2;
    ${ellipsis(2)}
  }
`

export const BookModal: React.FC<{
  book: Book,
  user: PublicUser,
  onClose: () => void
}> = ({ book, user, onClose }) => {
  const cancelClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
  }, [])
  return <ModalLayout onClose={onClose}>
    <Contents onClick={cancelClick}>
      <h1>{book.title}</h1>
      <BookInfoWrap>
        <BookCoverWrap>
          {book.cover ? <img src={book.cover} alt={book.title} /> : <NoCover>
            <h4>{book.title}</h4>
            <p>{book.author ?? ""}</p>
          </NoCover>}
        </BookCoverWrap>
        <BookInfo>
          <h2>{book.author}</h2>
          <h3>{book.publisher ?? ""} {book.publishedDate}</h3>
          <AmazonLink>
            <a href={amazonLink(book)} target="_blank" rel="noreferrer">Amazonで見る</a>
          </AmazonLink>
        </BookInfo>
      </BookInfoWrap>
      <Description>{book.description}</Description>
      {book.memo.length > 0 &&
        <MemoWrap>
          <h4>読書メモ</h4>
          <MemoText>{book.memo}</MemoText>
        </MemoWrap>
      }
    </Contents>
  </ModalLayout>
}
