import { useContext } from 'react'
import { AppStateContext } from '../../contexts/app/AppContext'
import { TopLayout } from '../templates/TopLayout'
import { InfoListView } from '../molecules/InfoListView'

export const InfoPage: React.FC = () => {

  const { informations } = useContext(AppStateContext)

  return <TopLayout>
    <InfoListView informations={informations} />
  </TopLayout>
}
