export interface Book {
  isbn: string;
  cover?: string;
  title: string;
  publisher?: string;
  author?: string;
  description: string;
  publishedDate: string;
  memo: string
  createdAt: Date;
}

export const amazonLink = (book: Book): string => {
  const isbn10 = toIsbn10(book.isbn)
  const affiliateTag = "shimoigi-22"
  if (isbn10.length < 10) {
    return `https://www.amazon.co.jp/s?k=${book.title}&tag=${affiliateTag}`
  }
  return `https://www.amazon.co.jp/dp/${isbn10}/?tag=${affiliateTag}`
};

export const amazonCoverIfNeeded = (book: Book): string => {
  if (book.cover) {
    return book.cover
  }
  return amazonCover(book)
}

export const amazonCover = (book: Book): string => {
  const isbn10 = toIsbn10(book.isbn)
  return `https://images-na.ssl-images-amazon.com/images/P/${isbn10}.09.LZZZZZZZ.jpg`
}

const toIsbn10 = (isbn13: string): string => {
  var src = isbn13.split("").slice(-10)
  src = src.slice(0, 9)
  var total = 0
  var index = 10

  let ar = src

  for (let i = 0; i < ar.length; i++) {
    let row = ar[i]
    total = total + parseInt(row) * index
    index = index - 1
  }

  let jo = 11 - (total % 11)
  let joString = joToX(jo)

  let isbn10 = ar.join("") + joString

  return isbn10
}

const joToX = (jo: number): string => {
  if (jo === 10) {
    return "X"
  }

  if (jo > 10) {
    return "0"
  }

  return `${jo}`
}