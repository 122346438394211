import styled from 'styled-components'
import { Book } from '../../models/Book'
import { BookShelfListBlock, BookShelfListWrap } from './ShelfViewHelper'
import { useCallback, useEffect, useRef } from 'react'
import { useInView } from '../../hooks/useInView'
import { MoreLoading } from '../atoms/Styles'
import { ellipsis } from '../../styles'

const Wrap = styled.div`
  padding: 0px;
  container-type: inline-size;
  margin-top:30px;
`

export const ShelfViewPublic: React.FC<{
  books: Book[],
  hasNextPage: boolean,
  loadMore: () => void,
  onSelectBook: (book: Book) => void
}> = ({ books, hasNextPage, loadMore, onSelectBook }) => {

  const moreLoadRef = useRef<HTMLDivElement>(null)
  const inView = useInView(moreLoadRef)

  useEffect(() => {
    if (inView && hasNextPage) {
      loadMore()
    }
  }, [hasNextPage, inView, loadMore])

  return <Wrap>
    <BookShelfListWrap>
      <Contents
        books={books}
        onSelectBook={onSelectBook}
      />
    </BookShelfListWrap>
    <MoreLoading ref={moreLoadRef} $hided={!hasNextPage}><p>Loading</p></MoreLoading>
  </Wrap>
}

const ContentsWrap = styled.div`
  position: relative;
  cursor: pointer;
`

const NoCover = styled.div`
  padding: 10px;
  padding-top:30px;
  text-align: center;
  h4{
    font-size:12px;
    ${ellipsis(2)}
  }
  p{
    margin-top:20px;
    font-size: 10px;
    line-height: 1.2;
    ${ellipsis(2)}
  }
`

const Contents: React.FC<{
  books: Book[]
  onSelectBook: (book: Book) => void
}> = ({ books, onSelectBook }) => {
  return <>
    {books.map((book, index) => {
      return <ContentsRow
        key={index}
        book={book}
        isFirst={index === 0}
        isLast={index === books.length - 1}
        onSelectBook={onSelectBook}
      />
    })}
  </>
}

const ContentsRow: React.FC<{
  book: Book
  isFirst: boolean
  isLast: boolean
  onSelectBook: (book: Book) => void
}> = ({ book, isFirst, isLast, onSelectBook }) => {

  const handleSelectBook = useCallback(() => {
    onSelectBook(book)
  }, [book, onSelectBook])

  return <BookShelfListBlock $isSelected={false}>
    <ContentsWrap onClick={handleSelectBook}>
      {book.cover ? <img src={book.cover} alt={book.title} className='cover' /> : <NoCover className="nocover">
        <h4>{book.title}</h4>
        <p>{book.author ?? ""}
        </p>
      </NoCover>}
    </ContentsWrap>
  </BookShelfListBlock>
}
