import React from 'react';
import { AppState, initialAppState } from './AppState';


export enum AppStateActionType {
  SET_INFORMATIONS = "SET_INFORMATIONS",
}

export interface AppStateAction {
  type: AppStateActionType;
  payload?: any;
}


const appReducer = (state: AppState, action: AppStateAction): AppState => {
  switch (action.type) {
    case AppStateActionType.SET_INFORMATIONS: {
      return {
        ...state,
        informations: action.payload
      }
    }

    default:
      return state
  }
}

export const AppStateContext = React.createContext<AppState>(initialAppState);
export const AppDispatchContext = React.createContext<React.Dispatch<AppStateAction>>(() => { })

export const AppStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer<React.Reducer<AppState, AppStateAction>>(appReducer, initialAppState)

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}
