import { RouterProvider } from 'react-router-dom';
import { router } from './routing';
import { AppStateProvider } from './contexts/app/AppContext';

declare global {
  interface Window {
    BarcodeDetector: any;
  }
}

function App() {
  return (
    <AppStateProvider>
      <RouterProvider router={router} />
    </AppStateProvider>
  );
}

export default App;
