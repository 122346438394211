import { RouteObject, createBrowserRouter } from 'react-router-dom';
import { Root } from './Root';
import { TopPage } from './components/pages/TopPage';
import { NotfoundPage } from './components/pages/NotfoundPage';
import { PublicPage } from './components/pages/public/PublicPage';
import { InfoPage } from './components/pages/InfoPage';
import { AppPrivacyPolicyPage } from './components/pages/public/app/AppPrivacyPolicyPage';
import { AppTermsPage } from './components/pages/public/app/AppTermsPage';
import { AndroidPrivacyPolicyPage } from './components/pages/public/android/AndroidPrivacyPolicyPage';
import { AndroidAppTermsPage } from './components/pages/public/android/AndroidAppTermsPage';

const pages: RouteObject[] = [
  {
    path: "/",
    element: <TopPage />,
  },
  {
    path: "/info",
    element: <InfoPage />,
  },
  {
    path: "/app/privacy-policy",
    element: <AppPrivacyPolicyPage />,
  },
  {
    path: "/app/terms",
    element: <AppTermsPage />,
  },
  {
    path: "/android/privacy-policy",
    element: <AndroidPrivacyPolicyPage />,
  },
  {
    path: "/android/terms",
    element: <AndroidAppTermsPage />,
  },
  {
    path: "/:path",
    element: <PublicPage />
  },
  {
    path: "/:path/book/:isbn",
    element: <PublicPage />
  }
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotfoundPage />,
    children: pages,
  },
]);
