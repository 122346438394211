import styled from 'styled-components'
import { colors } from '../../styles'

export const BookShelfListWrap = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  justify-content: center;
  gap: 0px 0px;
  padding: 0px 0px;
  padding-top: 30px;
  background: url("/images/login_shelf_right.png") no-repeat right bottom, url("/images/login_shelf_center.png") repeat-x bottom;
  @media (prefers-color-scheme: dark) {
    background: url("/images/login_shelf_right_dark.png") no-repeat right bottom, url("/images/login_shelf_center_dark.png") repeat-x bottom;
  }

  @container (max-width: 540px){
    padding-top: 20px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  @container (max-width: 420px){
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  li {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 200px;
    div {
      img, .nocover {
        height: 130px;
        margin-top: -30px;
        aspect-ratio: 1/1.5;
        object-fit: cover;
        background: ${colors.systemBackground};
      }
    }
  }
`

// 180
// 360
// 540
// 720
// 900

export const BookShelfListBlock = styled.li<{ $isSelected: boolean }>`
  // 3 以下
  @container (max-width: 540px){
    div {
      img.cover, .nocover {
        height: 120px;
        margin-top: -20px;
      }
    }

    background: url("/images/login_shelf_center.png") repeat-x;
    @media (prefers-color-scheme: dark) {
      background:  url("/images/login_shelf_center_dark.png") repeat-x;
    }
    // 左
    &:nth-child(3n+1) {
      padding-left: 30%;
      background:url("/images/login_shelf_left.png") left top no-repeat, url("/images/login_shelf_center.png") repeat-x;
      @media (prefers-color-scheme: dark) {
        background:url("/images/login_shelf_left_dark.png") left top no-repeat, url("/images/login_shelf_center_dark.png") repeat-x;
      }
    }
    // 右
    &:nth-child(3n) {
      padding-right: 30%;
      background: url("/images/login_shelf_right.png") no-repeat right top, url("/images/login_shelf_center.png") repeat-x;
      @media (prefers-color-scheme: dark) {
        background: url("/images/login_shelf_right_dark.png") no-repeat right top, url("/images/login_shelf_center_dark.png") repeat-x;
      }
    }
  }

  // 3
  @container (min-width: 540px) and (max-width: 719px) {
    background: url("/images/login_shelf_center.png") repeat-x;
    @media (prefers-color-scheme: dark) {
      background:  url("/images/login_shelf_center_dark.png") repeat-x;
    }
    // 左
    &:nth-child(3n+1) {
      padding-left: 45%;
      background:url("/images/login_shelf_left.png") left top no-repeat, url("/images/login_shelf_center.png") repeat-x;
      @media (prefers-color-scheme: dark) {
        background:url("/images/login_shelf_left_dark.png") left top no-repeat, url("/images/login_shelf_center_dark.png") repeat-x;
      }
    }
    // 右
    &:nth-child(3n) {
      padding-right: 45%;
      background: url("/images/login_shelf_right.png") no-repeat right top, url("/images/login_shelf_center.png") repeat-x;
      @media (prefers-color-scheme: dark) {
        background: url("/images/login_shelf_right_dark.png") no-repeat right top, url("/images/login_shelf_center_dark.png") repeat-x;
      }
    }
  }

  // 4
  @container (min-width: 720px) and (max-width: 899px){
    background: url("/images/login_shelf_center.png") repeat-x;
    @media (prefers-color-scheme: dark) {
      background:  url("/images/login_shelf_center_dark.png") repeat-x;
    }
    // 左
    &:nth-child(4n+1) {
      padding-left: 45%;
      background:url("/images/login_shelf_left.png") left top no-repeat, url("/images/login_shelf_center.png") repeat-x;
      @media (prefers-color-scheme: dark) {
        background:url("/images/login_shelf_left_dark.png") left top no-repeat, url("/images/login_shelf_center_dark.png") repeat-x;
      }
    }
    &:nth-child(4n+2) {
      padding-left: 17%;
    }
    &:nth-child(4n+3) {
      padding-right: 17%;
    }
    // 右
    &:nth-child(4n) {
      padding-right: 45%;
      background: url("/images/login_shelf_right.png") no-repeat right top, url("/images/login_shelf_center.png") repeat-x;
      @media (prefers-color-scheme: dark) {
        background: url("/images/login_shelf_right_dark.png") no-repeat right top, url("/images/login_shelf_center_dark.png") repeat-x;
      }
    }
  }

  // 5
  @container (min-width: 900px) {
    background: url("/images/login_shelf_center.png") repeat-x;
    @media (prefers-color-scheme: dark) {
      background:  url("/images/login_shelf_center_dark.png") repeat-x;
    }
    &:nth-child(5n+1) {
      padding-left: 45%;
      background:url("/images/login_shelf_left.png") left top no-repeat, url("/images/login_shelf_center.png") repeat-x;
      @media (prefers-color-scheme: dark) {
        background:url("/images/login_shelf_left_dark.png") left top no-repeat, url("/images/login_shelf_center_dark.png") repeat-x;
      }
    }
    &:nth-child(5n+2) {
      padding-left: 23%;
    }
    &:nth-child(5n+4) {
      padding-right: 23%;
    }
    &:nth-child(5n) {
      padding-right: 45%;
      background: url("/images/login_shelf_right.png") no-repeat right top, url("/images/login_shelf_center.png") repeat-x;
      @media (prefers-color-scheme: dark) {
        background: url("/images/login_shelf_right_dark.png") no-repeat right top, url("/images/login_shelf_center_dark.png") repeat-x;
      }
    }
  }

  ${({ $isSelected }) => $isSelected && `
    img, .nocover {
      opacity: 0.5;
      scale: 0.98;
      transform-origin: bottom;
      transition: scale 0.2s, opacity 0.2s;
    }
  `}}
`

export const BookShelfAddBookBlock = styled(BookShelfListBlock)`
  button {
    height: 130px;
    margin-top: -30px;
    aspect-ratio: 1/1.5;
    object-fit: cover;
    background: ${colors.accent};
    text-align: center;
    border-radius: 5px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }

    @container (max-width: 540px){
      height: 120px;
      margin-top: -20px;
    }
  }
`

export const BookShelfFinishSortBlock = styled(BookShelfAddBookBlock)`
  button {
    background: ${colors.green};
  }
`

export const EmptyContents: React.FC<{ count: number }> = ({ count }) => {
  return <>
    {Array.from({ length: count }).map((_, index) => {
      return <BookShelfListBlock className='empty' $isSelected={false} key={index}></BookShelfListBlock>
    })
    }
  </>
}