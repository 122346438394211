import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_AUTH_DOMAIN, FIREBASE_MEASURMENT_ID, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_RECAPTCHA_PUBLIC_KEY } from '../constants';

declare global {
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined
}

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASURMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);

const firebaseFunctions = getFunctions(firebaseApp)
firebaseFunctions.region = "asia-northeast1";

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

const firebaseAuth = getAuth(firebaseApp);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.


initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(FIREBASE_RECAPTCHA_PUBLIC_KEY!),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export { firebaseApp, firebaseFunctions, firebaseAuth }