import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display:flex;
    justify-content:center;
`

const Contents = styled.div`
    width:100%;
    max-width:680px;
    padding:20px;
`

const Section = styled.div`
    margin-top:40px;
`

const Title = styled.h1`
    font-size:40px;
    font-weight:bold;
    @media (max-width: 640px) {
        font-size:32px;
    }
`

const SubTitle = styled.h2`
    font-size:24px;
    font-weight:bold;

    @media (max-width: 640px) {
        font-size:20px;
    }
`

const Sentence = styled.p`
    font-size:16px;
    margin-top:20px;
    line-height:1.8;
`

const dataList = [
  {
    title: "1. 個人情報の定義",
    text: "本プライバシーポリシーにおいて「個人情報」とは、個々のユーザーに関する情報であって、当該情報に含まれる氏名、性別、生年月日、会員ID、パスワード、電子メールアドレス、住所、電話番号、勤務先会社名、部署名、役職、職業、クレジットカード情報、購入履歴、購入ポイント等保有情報およびポイント利用履歴、その他連絡先および商品の購入に関する情報のうち、一つまたは複数の組み合わせにより、特定の個人を識別することができる属性情報をいいます。"
  },
  {
    title: "2. 個人情報の取得について",
    text: "現在のバージョン(ver 1.x)において個人情報は取得しておりません。"
  },
  {
    title: "3. 本方針の変更について",
    text: "アプリ提供者は、社会情勢等の環境の変化を踏まえ、個人情報に関するマネジメントシステムを継続的に見直し、その改善を図っていきます。その際は、最新の方針を本アプリ内にてお知らせいたします。"
  },
]


export const AndroidPrivacyPolicyPage: React.FC = () => {
  return <Wrapper>
    <Contents>
      <Title>プライバシーポリシー</Title>
      <Sentence>Yu Kawase（以下「アプリ提供者」といいます）は、利用者に優れたサービスを提供することによって、利用者の読書生活を良いものにしていくために以下の個人情報保護方針を定めます。</Sentence>
      {dataList.map((row, index) => <Section key={index}>
        <SubTitle>{row.title}</SubTitle>
        <Sentence>{row.text}</Sentence>
      </Section>)}
    </Contents>
  </Wrapper>
}
