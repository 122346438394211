import styled from 'styled-components'
import { colors } from '../../styles'
import { ReactComponent as SavageIcon } from "../atoms/svgs/savage-icon.svg";
import { ReactComponent as AppStoreBadge } from "../atoms/svgs/appstorebadge.svg";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  svg {
    width: 100px;
  }
`

const IconWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  svg {
    width: 100px;
    fill: ${colors.accent};
  }
`

const Lead = styled.div`
  margin-bottom:10px;
  text-align: center;
  h2{
    font-size: 1.6rem;
    color: ${colors.accent};
  }
  p {
    margin-top:20px;
    font-size: 1.0rem;  
  }
`

const BadgeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const AppStoreBadgeWrap = styled.div`
  svg {
    width: 150px;
  }
`

const PlayStoreBadgeWrap = styled.div`
  img {
    width: 150px;
  }
`

export const LoginInputView: React.FC = () => {

  return <Wrap>
    <IconWrap>
      <SavageIcon />
    </IconWrap>
    <Lead>
      <h2>Savage</h2>
      <p>自分のための書管理</p>
    </Lead>
    <BadgeWrap>
      <AppStoreBadgeWrap>
        <a href="https://apps.apple.com/jp/app/id1496502686" target='_blank' rel="noopener noreferrer">
          <AppStoreBadge />
        </a>
      </AppStoreBadgeWrap>
      <PlayStoreBadgeWrap>
        <a href="https://play.google.com/store/apps/details?id=vc.savage.app" target='_blank' rel="noopener noreferrer">
          <img src="/images/google-play-badge.png" alt="Google Playで手に入れよう" />
        </a>
      </PlayStoreBadgeWrap>
    </BadgeWrap>
  </Wrap>
}
