import styled, { keyframes } from 'styled-components'
import { colors } from '../../styles'

const Wrap = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const spin = keyframes`
to {
  transform: rotate(360deg);
}
`;

const Loader1 = styled.div<{ $size: number }>`
  ${({ $size }) => `
    width: ${$size}px;
    height: ${$size}px;
    border: solid ${$size / 10}px;
  `}
  
  border-radius: 50%;
  border-color: ${colors.secondaryLabel} ${colors.systemGray4} ${colors.systemGray4};
  position: relative;
  animation-name: ${spin};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

export const Loader: React.FC<{ size?: number }> = ({ size }) => {
  const _size = size || 40
  return <Wrap>
    <Loader1 $size={_size} />
  </Wrap>
}