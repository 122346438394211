import styled from 'styled-components';


export const MoreLoading = styled.div<{ $hided: boolean }>`
  margin-bottom: 40px;
  p {
    text-align: center;
    opacity: 0.0;
  }
  visibility: ${({ $hided }) => $hided ? 'hidden' : 'visible'};
`
