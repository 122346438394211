import { firebaseFunctions } from './firebase';
import { Book } from '../models/Book';
import { httpsCallable } from 'firebase/functions';
import { Shelf } from '../models/Shelf';
import { Information } from '../models/Information';

export interface FirebaseDate {
  _nanoseconds: number
  _seconds: number
}

export interface BookRaw {
  cover?: string
  author?: string
  isbn: string
  publisher?: string
  description?: string,
  publishedDate?: string,
  title: string,
  memo?: string
}

export interface ShelfRaw {
  books: BookRaw[]
}

interface InformationRaw {
  id: string;
  title: string;
  content: string;
  link?: string;
  link_url?: string;
  published_at: FirebaseDate;
}

const informationFromRaw = (raw: InformationRaw): Information => {
  return {
    id: raw.id,
    title: raw.title,
    content: raw.content,
    link: raw.link,
    linkUrl: raw.link_url,
    publishedAt: firebaseDateToDate(raw.published_at)
  }
}

interface PublicUserRaw {
  name: string
  icon: string
  description: string
  shelf: ShelfRaw
}

export interface PublicUser {
  name: string
  icon: string
  description: string
  shelf: Shelf
}

const publicUserFromRaw = (raw: PublicUserRaw): PublicUser => {
  return {
    name: raw.name,
    icon: raw.icon,
    description: raw.description,
    shelf: shelfFromRaw(raw.shelf)
  }
}

export const firebaseDateToDate = (firebaseDate: FirebaseDate): Date => {
  return new Date(firebaseDate._seconds * 1000)
}

export const shelfFromRaw = (raw: ShelfRaw): Shelf => {
  return {
    books: raw.books.map((bookRaw) => {
      return bookFromRaw(bookRaw)
    })
  }
}

export const bookFromRaw = (raw: BookRaw): Book => {
  return {
    isbn: raw.isbn,
    cover: raw.cover,
    title: raw.title,
    publisher: raw.publisher,
    author: raw.author,
    description: raw.description ?? "",
    publishedDate: raw.publishedDate ?? "",
    memo: raw.memo ?? "",
    createdAt: new Date()
  }
}

export const fetchInformations = async (): Promise<Information[]> => {
  const fetchInformations = httpsCallable<{}, InformationRaw[]>(firebaseFunctions, "informations");
  const result = await fetchInformations({})
  return result.data.map((raw: InformationRaw) => {
    return informationFromRaw(raw)
  })
}

export const fetchPublicUser = async (path: string): Promise<PublicUser> => {
  const fetchPublicUser = httpsCallable<{ path: string }, PublicUserRaw>(firebaseFunctions, "publicUser");
  const result = await fetchPublicUser({ path })
  return publicUserFromRaw(result.data)
}

export const testAppCheck = async (): Promise<string> => {
  const appcheckString = httpsCallable<{}, string>(firebaseFunctions, "appcheckString");
  const result = await appcheckString({})
  return result.data
}