
const {
  REACT_APP_FIREBASE_API_KEY: FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN: FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID: FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET: FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID: FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASURMENT_ID: FIREBASE_MEASURMENT_ID,
  REACT_APP_FIREBASE_RECAPTCHA_PUBLIC_KEY: FIREBASE_RECAPTCHA_PUBLIC_KEY,
  REACT_APP_ROOT_URL: ROOT_URL,
} = process.env;

export {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASURMENT_ID,
  FIREBASE_RECAPTCHA_PUBLIC_KEY,
  ROOT_URL,
}
