import styled from 'styled-components'
import { BookShelfListWrap, EmptyContents } from './ShelfViewHelper'

const Wrap = styled.div`
  padding: 0px;
  container-type: inline-size;
  margin-top:30px;
`

export const TopShelfView: React.FC<{
}> = () => {

  return <Wrap>
    <BookShelfListWrap>
      <EmptyContents count={11} />
    </BookShelfListWrap>
  </Wrap>
}
