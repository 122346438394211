import styled from 'styled-components';
import { Information } from '../../models/Information';
import { colors } from '../../styles';

const Informations = styled.div`
  display: grid;
  gap: 30px;
`

const InformationRow = styled.div`
  display: grid;
  gap: 10px;
  opacity: 0.3;
  &:first-child {
    opacity:0.9;
  }
  &:hover {
    opacity: 1;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 0.8rem;
    font-weight: normal;
    color: ${colors.secondaryLabel};
  }

  p {
    font-size: 0.9rem;
  }
`

export const InfoListView: React.FC<{ informations: Information[] }> = ({ informations }) => {
  return <Informations>
    {informations.map((info, i) => {
      const { link, linkUrl } = info
      return <InformationRow key={info.id}>
        <h2>{info.title}</h2>
        <h3>{info.publishedAt.toLocaleDateString()}</h3>
        <p>{info.content}</p>
        {link && link && <p>
          <a href={linkUrl} target="_blank" rel="noreferrer">{link}</a>
        </p>}
      </InformationRow>
    })}
  </Informations>
}