import styled from 'styled-components'
import { colors } from '../../styles'
import { ReactComponent as SavageIcon } from "../atoms/svgs/savage-icon.svg";
import { NavLink } from 'react-router-dom'

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  position:relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Left = styled.div`
  pointer-events: none;
  a {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  svg {
    width: 40px;
    fill: ${colors.secondarySystemBackground};
  }
  h1 {
    color: ${colors.accent};
    color: ${colors.secondarySystemBackground};
  }
`

export const DefaultHeader: React.FC = () => {

  return <Wrap>
    <Left>
      <NavLink to="/">
        <SavageIcon />
        <h1>Savage</h1>
      </NavLink>
    </Left>
  </Wrap>
}
