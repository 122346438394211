import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { NotfoundPage } from '../NotfoundPage'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PublicUser, fetchPublicUser } from '../../../api/functionsApi'
import styled from 'styled-components'
import { MQ, colors, ellipsis, headerHeight } from '../../../styles'
import { ReactComponent as SavageIcon } from "../../atoms/svgs/savage-icon.svg";
import { ShelfViewPublic } from '../../organisms/ShelfViewPublic'
import { Book } from '../../../models/Book'
import { BookModal } from '../../organisms/modal/BookModal'
import { Loader } from '../../atoms/Loader'

const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const Contents = styled.div`
  width: 100%;
  max-width: 1000px;
`
const PublicHeaderView = styled.div`
  top: 0px;
  height: ${headerHeight}px;
  padding: 20px;
  position: sticky;
  background: ${colors.systemBackground};
  z-index: 10;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  h1{
    font-size: 1.2rem;
  }

  ${MQ.SP} {
    h1{
      display: none;
    }
  }
`

const ShelfWrap = styled.div`
  margin-top: 20px;
`

const Left = styled.div`

  min-width: 50px;

  a {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  svg {
    width: 40px;
    fill: ${colors.accent};
  }
  h1 {
    color: ${colors.accent};
  }
`

const Right = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: right;

  > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    h2{
      font-size: 1.1rem;
    }
    p {
      font-size: 0.8rem;
      width: 100%;
      ${ellipsis(1)}
      max-width: 350px;

      ${MQ.SP} {
        max-width: 220px;
      }
    }
  }
`

const LoaderWrap = styled.div`
  width: 100%;
  height: 100vh;
`

const useShowedCount = (booksCount: number) => {
  const [showedBookCount, setShowedBookCount] = useState(12)
  const hasNextPage = booksCount > showedBookCount
  const loadMore = () => {
    setShowedBookCount(showedBookCount + 20)
  }

  return {
    showedBookCount,
    hasNextPage,
    loadMore,
  }
}

export const PublicPage: React.FC = () => {
  const { path, isbn } = useParams()
  const [user, setUser] = useState<PublicUser | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const books = useMemo(() => user?.shelf.books ?? [], [user])

  const { showedBookCount, hasNextPage, loadMore } = useShowedCount(books.length)
  const showedBooks = books.slice(0, showedBookCount)

  const currentBook = useMemo(() => {
    if (!isbn) return undefined
    return books.find((book) => book.isbn === isbn)
  }, [isbn, books])

  useEffect(() => {
    if (!path) return

    setLoading(true)
    fetchPublicUser(path).then((user) => {
      setUser(user)
    })
      .catch(() => {

      }).finally(() => {
        setLoading(false)
      })
  }, [path])

  const onSelectBook = useCallback((book: Book) => {
    navigate(`book/${book.isbn}`)
  }, [navigate])

  const onCloseBookModal = useCallback(() => {
    navigate(`/${path}`)
  }, [navigate, path])

  if (!path) return <NotfoundPage />
  if (loading) return <LoaderWrap><Loader /></LoaderWrap>
  if (!user) return <NotfoundPage />

  return <Wrap>
    <Contents>
      <PublicHeaderView>
        <Left>
          <NavLink to="/">
            <SavageIcon />
            <h1>Savage</h1>
          </NavLink>
        </Left>
        <Right>
          <div>
            <h2>{user.name}の本棚</h2>
          </div>
        </Right>
      </PublicHeaderView>
      <ShelfWrap>
        <ShelfViewPublic
          books={showedBooks}
          hasNextPage={hasNextPage}
          loadMore={loadMore}
          onSelectBook={onSelectBook}
        />
      </ShelfWrap>
    </Contents>
    {currentBook && <BookModal book={currentBook} user={user} onClose={onCloseBookModal} />}
  </Wrap>
}
