export const colors = {
  accent: "var(--accent)",
  link: "var(--link)",
  green: "var(--green)",
  red: "var(--red)",
  white: "var(--white)",
  black: "var(--black)",

  label: "var(--label)",
  labelInAccent: "var(--labelInAccent)",
  secondaryLabel: "var(--secondaryLabel)",
  tertiaryLabel: "var(--tertiaryLabel)",
  quaternaryLabel: "var(--quaternaryLabel)",
  placeholderText: "var(--placeholderText)",

  bg: "var(--bg)",
  backgroundOpacity: "var(--backgroundOpacity)",
  systemBackground: "var(--systemBackground)",
  secondarySystemBackground: "var(--secondarySystemBackground)",
  secondarySystemBackgroundOpacity: "var(--secondarySystemBackgroundOpacity)",
  tertiarySystemBackground: "var(--tertiarySystemBackground)",
  systemGroupedBackground: "var(--systemGroupedBackground)",
  secondarySystemGroupedBackground: "var(--secondarySystemGroupedBackground)",
  tertiarySystemGroupedBackground: "var(--tertiarySystemGroupedBackground)",

  systemGray: "var(--systemGray)",
  systemGray2: "var(--systemGray2)",
  systemGray3: "var(--systemGray3)",
  systemGray4: "var(--systemGray4)",
  systemGray5: "var(--systemGray5)",
  systemGray6: "var(--systemGray6)",

  separator: "var(--separator)",
  opaqueSeparator: "var(--opaqueSeparator)",
  shadowColor: "var(--shadowColor)",
  pushBgBlack: "var(--pushBgBlack)",
  modalBgBlack: "var(--modalBgBlack)",

  gradeStart: "var(--gradeStart)",
  gradeEnd: "var(--gradeEnd)",

  modalGradeStart: "var(--modalGradeStart)",
  modalGradeEnd: "var(--modalGradeEnd)",
  blurBg: "var(--blurBg)",
  blurSeparator: "var(--blurSeparator)",
  outlineColor: "var(--outlineColor)",
}

export const accentColorOpacitied = (opacity: number) => {
  return `rgba(136, 136, 247, ${opacity})`
}

export const labelColorOpacitied = (opacity: number) => {
  return `rgba(136, 136, 247, ${opacity})`
}

export const headerHeight = 80
export const maxContentsWidth = 600

export const ellipsis = (lineLimit: number) => `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineLimit};
  overflow: hidden;
`

const bp = {
  ONE_COLUMN: 900,
  SP: 620,
  ONE_MODAL: 760
}

export const MQ = {
  ONE_COLUMN: `@media (max-width: ${bp.ONE_COLUMN}px)`,
  SP: `@media (max-width: ${bp.SP}px)`,
  ONE_MODAL: `@media (max-width: ${bp.ONE_MODAL}px)`
}


const device = () => {
  const ua = navigator.userAgent;
  if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
    return 'mobile';
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    return 'tablet';
  } else {
    return 'desktop';
  }
}

export const isMobile = (): boolean => {
  return device() !== 'desktop'
}